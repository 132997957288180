export class Inicio {
    username: string;
    email: string;
    password: string;
    uid: string;
    picture: string;
    userslug: string;
    token: string;
    notifications: string;
    topicTitle: string;
}
